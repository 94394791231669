<template>
  <div class="thing_order_list">
    <el-card>
      <el-row class="left">
        <el-col :span="24">
          <el-form :inline="true" class="demo-form-inline">
            <el-form-item>
							<el-input v-model="consignee" placeholder="请输入收货人" clearable></el-input>
						</el-form-item>
            <el-form-item>
              <el-input v-model="order_sn" placeholder="请输入订单号" clearable></el-input>
            </el-form-item>
            <el-form-item>
              <el-select v-model="status" placeholder="请选择状态">
                <el-option label="全部" value="0"></el-option>
                <el-option label="待付款" value="8000"></el-option>
                <el-option label="待发货" value="8120"></el-option>
                <el-option label="待揽收" value="8121"></el-option>
                <el-option label="待收货" value="8122"></el-option>
                <el-option label="已取消" value="8300"></el-option>
                <el-option label="已完成" value="8223"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
							<el-button type="primary" @click="search" icon="el-icon-search">搜索</el-button>
							<el-button type="default" @click="reset" icon="el-icon-refresh">重置</el-button>
						</el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <el-table 
        id="goodsListTable"
        :data="list"
        @select="select"
        @select-all="selectAll" 
				style="width: 100%"
				:header-cell-style="{ background: '#eef1f6', color: '#606266' }">
        <el-table-column type="selection"></el-table-column>
        <el-table-column label="订单号" width="110" prop="order_sn"></el-table-column>
        <el-table-column label="下单时间" prop="add_time"></el-table-column>
        <el-table-column label="收货人" width=360 prop="shr"></el-table-column>
        <el-table-column label="总价" prop="order_amount">
          <template slot-scope="scope">
            <div>
              <span>&yen;{{scope.row.order_amount}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="状态" prop="status_name"></el-table-column>
        <el-table-column label="操作" fixed="right">
          <template slot-scope="scope">
            <div style="text-align: left;">
              <el-button size="mini" type="success" plain @click="setOrder(scope.row.order_id)">详情</el-button>
            </div>
          </template>
        </el-table-column>
			</el-table>
      <Pagination :queryInfo="queryInfo" :total="total" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"></Pagination>
    </el-card>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination/Pagination.vue'
export default {
  components:{
    Pagination
  },
  data () {
    return {
      queryInfo: {
        pagenum: 1,
        pagesize: 10,
      },
      total:10,

      consignee:'',
      order_sn:'',
      status: null,

      chooseList:[],
      list:[]
    }
  },
  created() {
		if(!this.$store.state.userInfo) {
			// 防止未登录
			this.$router.push({
				path: '/login'
			})
		} else {
			this.getList();
		}
	},
  methods: {
    getList(){
			var url = 'thing/order_list';
			let params = {
        consignee: this.consignee,
        order_sn: this.order_sn,
				status: this.status,
				size: this.queryInfo.pagesize,
				page: this.queryInfo.pagenum,
			};
			this.fd_post(url, params).then((res) => {
				if(res.status) {
					this.total = res.total;
					this.list = res.list;
				} else {
					this.$message.error(res.msg);
				}
			}).catch((err) => {
				this.$message.error('网络错误');
			});
    },
    //查询
    search(){
      this.getList();
    },
    // 重置
    reset() {
      this.consignee = "";
      this.order_sn = "";
      this.status = null;
      this.getList();
    },
    //页数改变
    handleSizeChange(newSize) {
      this.queryInfo.pagenum=1
      this.queryInfo.pagesize=newSize
      this.getList();
    },
    //页码改变
    handleCurrentChange(newPage) {
      this.queryInfo.pagenum=newPage
      this.getList();
    },
    //单选
    select(selection,row){
      this.chooseList=selection
      console.log(this.chooseList);
    },
    //全选
    selectAll(selection){
      this.chooseList=selection
      console.log(this.chooseList);
    },
    setOrder(order_id) {
      this.$router.push({path:'/thing_order_detail',query: {order_id: order_id}})
    }
  },
}
</script>

<style>
  .thing_order_list .vue-treeselect__placeholder, .vue-treeselect__single-value{
    line-height: 40px;
  }
  .thing_order_list .vue-treeselect__control{
    width: 200px;
  }
  .thing_order_list{

  }
</style>